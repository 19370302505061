import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';
import './pagination.scss';
const Pagination = props => {
  const {
    onPageChange,
     totalCount,
     pageRange,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
    totalAmount,
    setCurrentPage, setRecordsPerPage,
    

  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
    
  });

//   if (currentPage === 0 || paginationRange.length < 2) {
//     return null;
//   }


  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };
  const getRecords = (value) => {
    setRecordsPerPage(value);
    setCurrentPage(1);
}

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <div className='pagination-container'>
    <div className='flex-row'>
    <div>
                Page Size: <select onChange={(e) => getRecords(e.target.value)} defaultValue={pageSize}>
                
                    <option>25</option>
                  
                    <option>40</option>
                   
                    <option>50</option>
                  
                    <option>100</option>
                </select>
                <span className='count-records'> {pageRange} </span>
               Total Amount: <span className={parseFloat(totalAmount) < 0 ? 'count-records negative' : 'count-records positive'}>{parseFloat(totalAmount) < 0 ? '($' + totalAmount.slice(1) + ')' : '$' + totalAmount }</span>
            </div>

           
            <div>
    <ul
      className={classnames('pagination-container pagination', { [className]: className })}
    >
      <li key="prev-key"
        className= {classnames('pagination-item page-item', {
          disabled: currentPage === 1
        })}
        onClick={onPrevious}
      >
        <div className="arrow left" />
      </li>
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return <li key ={ index + 'dots'} className="pagination-item dots">&#8230;</li>;
        }

        return (
          <li key={pageNumber}
            className={classnames('pagination-item', {
              selected: pageNumber === currentPage
            })}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li key="next-key"
        className={classnames('pagination-item', {
          disabled: currentPage === lastPage
        })}
        onClick={onNext}
      >
        <div className="arrow right" />
      </li>
    </ul>
    </div>
    </div>
    </div>
  );
};

export default Pagination;
