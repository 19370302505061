import Button from 'react-bootstrap/Button';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { reimbursementExcelExportActions } from "../_store";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
export function ReimbursementExportModal(props) {
    const [excel, setExcel] = useState(false);
    const dispatch = useDispatch();
    let getFilterObj;
    let { reimbursementExcelExport } = useSelector(x => x.reimbursementExcelExport);
    
    useEffect(() => {
        if (localStorage.getItem('reimbersementFilterObjValues')) {
            getFilterObj = JSON.parse(localStorage.getItem('reimbersementFilterObjValues'))
            // dispatch(excelExportActions.getAll(getFilterObj));
        }

    }, [])


    const cancelModal = () => {
        props.onHide();
        setExcel(false)
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        if (excel) {
            if (localStorage.getItem('reimbersementFilterObjValues')) {
                getFilterObj = JSON.parse(localStorage.getItem('reimbersementFilterObjValues'))
                getFilterObj.pageNumber = '';
                getFilterObj.pageSize='';
                dispatch(reimbursementExcelExportActions.getAll(getFilterObj));
            }           

        }

    }
    useEffect(() => {
        if(reimbursementExcelExport.result) {
            const link = document.createElement('a');
            link.href = reimbursementExcelExport.result.fileRelativePath + '/' + reimbursementExcelExport.result.fileName;
            link.download = 'ExpenseReimbursement.xlsx'
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
           
            props.onHide();
            setExcel(false);
        }
    },[reimbursementExcelExport])
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="export-modal filter-modal"
            >
            <h4>Export Files</h4>
            <Modal.Body>
                <div className="form-overlay">
                    <div className="receipts edit-receipts">
                        {reimbursementExcelExport?.loading &&
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>}

                        <Form onSubmit={handleSubmit}>
                            <div className='export-dashboard'>
                                {!reimbursementExcelExport?.loading &&
                                    <div key='export-excel' className="checkbox-export">
                                        <Form.Check
                                            type="checkbox"
                                            id='export-excel'
                                            label="Export To Excel"
                                            required
                                            onChange={(event) => setExcel(event.target.checked)}
                                        />

                                        <Form.Check
                                            disabled
                                            type="checkbox"
                                            id='export-pdf'
                                            label="Export To PDF"
                                        />
                                        {reimbursementExcelExport?.error &&
                                            <div className='error-msg'>{reimbursementExcelExport?.error.message}</div>}
                                    </div>
                                }

                            </div>
                            <div className='flex'>
                                <OverlayTrigger
                                    key='bottom-filter'
                                    placement='bottom'
                                    overlay={
                                        <Tooltip id='tooltip-filter'>
                                            Export
                                        </Tooltip>
                                    }
                                >
                                    <Button type="submit"> <img src={'/assets/icons/checkmark.svg'} /></Button>

                                </OverlayTrigger>
                                <OverlayTrigger
                                    key='bottom-cancel'
                                    placement='bottom'
                                    overlay={
                                        <Tooltip id='tooltip-cancel'>
                                            Cancel
                                        </Tooltip>
                                    }
                                >
                                    <Button onClick={cancelModal} className="close-button"> <img src={'/assets/icons/close.svg'} /></Button>
                                </OverlayTrigger>
                            </div>
                        </Form>
                    </div>
                </div>
            </Modal.Body>

        </Modal>
    );

}