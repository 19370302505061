import { useEffect } from 'react';
import './login.css';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';

import { history } from '../_helpers';
import { authActions } from '../_store';

export { Login };

function Login() {
    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
    const authError = useSelector(x => x.auth.error);

    useEffect(() => {
        if (authUser) history.navigate('/');

    }, []);

 
    const validationSchema = Yup.object().shape({
        UserId: Yup.string().required('Username is required'),
        Password: Yup.string().required('Password is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };


    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    function onSubmit({ UserId, Password }) {
        const reqObject = {
            UserId: UserId,
            Password: Password

        }
        return dispatch(authActions.login(reqObject));
    }
  
    return (
        <div className="login">           
            <main>
                <div className="container">
                    <div className="row">
                        <div className="login-form col-sm-6">
                            <div className="form">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-group">
                                        <label>Username</label>
                                        <input name="UserId" type="text" {...register('UserId')} className={`form-control ${errors.UserId ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.UserId?.message}</div>
                                    </div>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input name="Password" type="password" {...register('Password')} className={`form-control ${errors.Password ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.Password?.message}</div>
                                    </div>
                                    <button disabled={isSubmitting} className="btn btn-primary">
                                        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                        Login
                                    </button>
                                    {authError &&
                                        <div className="alert alert-danger mt-3 mb-0">{authError.message}</div>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}
