import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import './App.scss';
import { history } from './_helpers';
import { Nav, PrivateRoute } from './_components';
import {IdleTimeout } from './_helpers/idle';
import { Home } from './home';
import { Login } from './login';
import { Dashboard } from './TekCCReceipts/Dashboard';
import { ReimbursementDashboard } from './ExpenseReimbursement/ExpenseReimbursementDashboard';
import { UploadReceipt } from './TekCCReceipts/UploadReceipt';
import { ExpenseReimbursementUploadReceipt } from './ExpenseReimbursement/ExpenseReimbursementUploadReceipt';
import { MileageReimbursementDashboard } from './MileageReimbursement/MileageReimbursementDashboard';
import { MileageReimbursementForm } from './MileageReimbursement/MileageReimbursementForm';
import { ValidationForm } from './validationForm';
import { MissingReceiptsDashboard } from './MissingReceipts/MissingReceiptsDashboard';



export { App };

function App() {
    history.navigate = useNavigate();
    history.location = useLocation();

    return (
        <div className="app-container">
           <div className="wrapper">
           <IdleTimeout timerDuration={1800000}/>
            {/* <Nav /> */}
            <div className="">
                <Routes>
                <Route
                    
                        path="/"
                        element={
                            <PrivateRoute>
                                <Home />       
                            </PrivateRoute>
                        }
                    />                  
                    <Route path="/Dashboard" element={<Dashboard />} />                 
                    <Route path="/ExpenseReimbursementDashboard" element={<ReimbursementDashboard />} />                 
                    <Route path="/UploadReceipt" element={<UploadReceipt />} />
                    <Route path="/ExpenseReimbursementUploadReceipt" element={<ExpenseReimbursementUploadReceipt />} />
                    <Route path="/MileageReimbursementForm" element={<MileageReimbursementForm />} />
                    <Route path="/MileageReimbursementDashboard" element={<MileageReimbursementDashboard />} />
                    <Route path="/MissingReceiptsDashboard" element={<MissingReceiptsDashboard />} />
                    
                    <Route path="/ValidationForm" element={<ValidationForm />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </div>
            </div>
            
        </div>
    );
}
