
import React, { useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import {FilterDashboard} from '../TekCCReceipts/FilterDashboard/FilterDashboard';
import {ReimbursementFilterDashboard} from '../ExpenseReimbursement/ExpenseReimbursementFilterDashboard/ExpenseReimbursementFilterDashboard';
import {MileageReimbursementFilterDashboard} from '../MileageReimbursement/MileageReimbursementFilterDashboard/MileageReimbursementFilterDashboard';
import { MissingReceiptsFilterDashboard } from '../MissingReceipts/MissingReceiptsFilterDashboard/MissingReceiptsFilterDashboard';


export function FilterModal(props) {
  

const overlayRef = useRef();
  
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      ref={overlayRef}
      className= {props.className + " filter-modal"}
    >
      
      <h4>Filter Records</h4>
      <Modal.Body>
   
     
       
        {props.reimbursement ? 
        <ReimbursementFilterDashboard sdata={props}></ReimbursementFilterDashboard> : 
        props.mileagereimbursement ? 
        <MileageReimbursementFilterDashboard sdata={props}></MileageReimbursementFilterDashboard> : 
         props.missingreceipts ?  <MissingReceiptsFilterDashboard sdata={props} className="missing-receipts"></MissingReceiptsFilterDashboard> :
        <FilterDashboard sdata={props}></FilterDashboard> }
        
      </Modal.Body>
     
    </Modal>
  );

    }