import React from 'react'

const Pagination = ({ nPages, currentPage, setCurrentPage, setRecordsPerPage, recordsPerPage, apipagination }) => {

    const pageNumbers = [...Array(nPages + 1).keys()]?.slice(1)

     

    const nextPage = () => {
            if(currentPage !== nPages) setCurrentPage(currentPage + 1)
    }
    const prevPage = () => {
        if(currentPage !== 1) setCurrentPage(currentPage - 1)
    }
    const getRecords = (value) => {
        setRecordsPerPage(value);
        setCurrentPage(1);
    }
    
    return (
        <div className='paginaton-container'>
        <div className='flex-row'>
            <div>
                Page Size: <select onChange={(e) => getRecords(e.target.value)} defaultValue={recordsPerPage}>
                
                    <option>25</option>
                  
                    <option>40</option>
                   
                    <option>50</option>
                  
                    <option>100</option>
                </select>
                <span className='count-records'> {apipagination.pageRange}</span>
            </div>
        <div>
            
            <ul className='pagination justify-content-center'>
                <li className="page-item">
               
                    <a className={nPages < 2 ? 'disabled page-link' : 'page-link'}
                        onClick={prevPage} 
                        href='#'>
                        
                        Previous
                    </a>
               
                </li>
                {pageNumbers.map(pgNumber => (
                    <li key={pgNumber} 
                        className= {`page-item ${currentPage == pgNumber ? 'active' : ''} `} >

                        <a onClick={() => setCurrentPage(pgNumber)}  
                            className='page-link' 
                            href='#'>
                            
                            {pgNumber}
                        </a>
                    </li>
                ))}
                <li className="page-item">
                    <a className={nPages < 2 ? 'disabled page-link' : 'page-link'}
                        onClick={nextPage}
                        href='#'>
                        
                        Next
                    </a>
                </li>
            </ul>
        </div>
        </div>
        </div>
    )
}

export default Pagination