import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';

// create slice

const name = 'dashboard';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const reimbursementDashboardActions = { ...slice.actions, ...extraActions };
export const reimbursementDashboardReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        dashboard: {},
        dashboardArgs: {}
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/Reimbursement`;

    return {
        getAll: getAll()
    };    

    function getAll() {
        return createAsyncThunk(
            `${name}/getAll`,
            async (postData)  => await fetchWrapper.post(`${baseUrl}/GetExpenseReimbursementDashboard`, {postData})
        );
    }
}

function createExtraReducers() {
    return {
        ...getAll()
    };

    function getAll() {
        var { pending, fulfilled, rejected } = extraActions.getAll;
        return {
            [pending]: (state) => {
                state.dashboard = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.dashboard = action.payload;
                state.dashboardArgs = action.meta.arg;                
            },
            [rejected]: (state, action) => {
                state.dashboard = { error: action.error };
            }
        };
    }
}
