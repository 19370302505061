import './Home.css';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { userActions } from '../_store';
import { creditcardActions } from '../_store';
export { Home };

function Home() {
    const dispatch = useDispatch();
    // const { user: authUser } = useSelector(x => x.auth);
    // const { users } = useSelector(x => x.users);
    const { creditcards } = useSelector(x => x.creditcards);

    useEffect(() => {
        dispatch(creditcardActions.getAll());
        // dispatch(userActions.getAll());


    }, []);

    return (
        <>
            <div className="App">
                <div className="login">
                    <main>
                        <div className="container">
                            <div className="row">
                                <div className="login-form">
                                    <div className="dashboard_upload">
                                    <div className="btns">
                                           
                                           <a href="/MissingReceiptsDashboard" className="btn btn-primary">MISSING RECEIPTS</a>
                                           
                                       </div>
                                        <div className="btns">
                                        <a href="/UploadReceipt" className="btn btn-primary">Tek CC Receipt</a>
                                            <a href="/Dashboard" className="btn btn-primary dashboard-btn" id="dahboard-btn">Tek CC Receipt Dashboard</a>
                                            
                                        </div>
                                        <div className="btns">
                                            
                                            <a href="/ExpenseReimbursementUploadReceipt" className="btn btn-primary">Expense Reimbursement</a>
                                            <a href="/ExpenseReimbursementDashboard" className="btn btn-primary dashboard-btn">Expense Reimbursement Dashboard</a>
                                        </div>
                                        <div className="btns">
                                           
                                            <a href="/MileageReimbursementForm" className="btn btn-primary">Mileage Reimbursement</a>
                                            <a href="/MileageReimbursementDashboard" className="btn btn-primary dashboard-btn">Mileage Reimbursement Dashboard</a>
                                        </div>
                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
}


