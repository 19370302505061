import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';

// create slice

const name = 'subtasks';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const subtasksActions = { ...slice.actions, ...extraActions };
export const subtasksReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        subtasks: {}
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/user`;

    return {
        getAllById: getAllById()
    };    

    function getAllById() {
        return createAsyncThunk(
            `${name}/getAllById`,
            async ({param}) => await fetchWrapper.get(`${baseUrl}/GetSubTasks/${param}`)
        );
    }
}

function createExtraReducers() {
    return {
        ...getAllById()
    };

    function getAllById() {
        var { pending, fulfilled, rejected } = extraActions.getAllById;
        return {
            [pending]: (state) => {
                state.subtasks = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.subtasks = action.payload;
            },
            [rejected]: (state, action) => {
                state.subtasks = { error: action.error };
            }
        };
    }
}
