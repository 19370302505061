import * as React from "react";
// import { NavLink } from 'react-router-dom';
import {useDispatch, useSelector } from 'react-redux';
import { authActions } from '../_store';
import {  useNavigate } from 'react-router-dom';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

export { Header };
 function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = () => dispatch(authActions.logout());
  const goToHome = () => {
    navigate ('/')
  }
  const { user: authUser } = useSelector(x => x.auth);
  return (
    <>  
        <div className="nav-cls">
      <nav>   
      {authUser ? (
      <div className="home-icon basic">
         <OverlayTrigger
                                key='bottom'
                                placement='bottom'
                                overlay={
                                    <Tooltip id='tooltip-bottom'>
                                        Home
                                    </Tooltip>
                                }
                               >
                                <img onClick={goToHome} src={'/assets/images/home.png'} className='home-icon'></img>
                                
                            </OverlayTrigger>
     
        </div> ): (<div className="basic"></div>)}

        <div className="text-center basic">
          <img src={'/assets/images/logo-tekzenit.png'} className="logo"/>
          {/* <h5>Receipts</h5> */}
        </div>
        {authUser ? (
        <div className="dashboard-icon basic">
          <div className="desk-top-user">
           {authUser.user.user_fname} {authUser.user.user_lname} <br></br>
        {new Date().toLocaleString("en-US", { weekday: "long" })} {new Date().toLocaleString("en-US", { month: "long" })} {new Date().toLocaleString("en-US", { day : '2-digit'})}, {new Date().getFullYear()} 

    </div>
    <div>
         <OverlayTrigger
                                key='bottom'
                                placement='bottom'
                                overlay={
                                    <Tooltip id='tooltip-bottom'>
                                        Logout
                                    </Tooltip>
                                }
                               >
                                <img onClick={logout} src={'/assets/images/logout.png'} className='logout'/>
                               </OverlayTrigger>
                               </div>
        </div>
        ): (<div className="basic"></div> ) 
        }
        </nav>
        {authUser &&
        <div className="mobile-user">
           {authUser.user.user_fname} {authUser.user.user_lname} <br></br>
        {new Date().toLocaleString("en-US", { weekday: "long" })} {new Date().toLocaleString("en-US", { month: "long" })} {new Date().toLocaleString("en-US", { day : '2-digit'})}, {new Date().getFullYear()} 

    </div>}
    </div>
    </>
  );
}