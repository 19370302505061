import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';

// create slice

const name = 'upload';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const uploadActions = { ...slice.actions, ...extraActions };
export const uploadReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        upload: {}
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/user`;

    return {
        postFiles: postFiles()
    };    

    function postFiles() {
       
            return createAsyncThunk(
                `${name}/postFiles`,
                async  (postData)  => await fetchWrapper.post(`${baseUrl}/UploadFile`, { postData})
            );
    }
}

function createExtraReducers() {
    return {
        ...postFiles()
    };

    function postFiles() {
        var { pending, fulfilled, rejected } = extraActions.postFiles;
        return {
            [pending]: (state) => {
                state.upload = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.success = action.payload;
            },
            [rejected]: (state, action) => {
                state.upload = { error: action.error };
            }
        };
    }
}
