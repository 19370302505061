import Button from 'react-bootstrap/Button';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { deleteRecordActions } from "../_store";
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { dashboardActions } from "../_store";
export function DeleteModal(props) {
    const [excel, setExcel] = useState(false);
    const dispatch = useDispatch();
    let  deleteReceipt  = useSelector(x => x.deleteRecord);

    const cancelModal = () => {
        props.onHide();
      
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(deleteRecordActions.deleteRecord(props.modaldata));
    }
    useEffect(() => {
        if(deleteReceipt.success) {
            props.onHide();
          
            const copyLocalObj = Object.assign({}, JSON.parse(localStorage.getItem('filterObjValues')));
            dispatch(dashboardActions.getAll(copyLocalObj));
        }
    },[deleteReceipt])
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="export-modal filter-modal delete-modal"
            >
            <h4>Delete Record</h4>
            <Modal.Body>
                <div className="form-overlay">
                    <div className="receipts edit-receipts">                      

                        <Form onSubmit={handleSubmit}>
                            <div className='export-dashboard'>
         <p>Are you sure you want to delete this record?</p>

                            </div>
                            <div className='flex'>
                            <Button type="submit"> Yes</Button>
                            <Button onClick={cancelModal} className="close-button"> No</Button>
                              
                            </div>
                        </Form>
                    </div>
                </div>
            </Modal.Body>

        </Modal>
    );

}