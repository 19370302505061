import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './UploadReceipt.css';
import { userActions } from '../../_store';
import { creditcardActions } from '../../_store';
import { clientsActions } from '../../_store';
import { payeeActions } from '../../_store';
import { projectsActions } from '../../_store';
import { tasksActions } from '../../_store';
import { subtasksActions } from '../../_store';
import { purchaseTypeActions } from '../../_store';
// import { uploadActions } from '../_store';
// import { saveReceiptsActions } from '../_store';
import Select from 'react-select';
import { Navigate, useNavigate } from 'react-router-dom';
import ImageUploading from 'react-images-uploading';
import { history } from '../../_helpers';
import CurrencyInput from 'react-currency-input-field';
import Spinner from 'react-bootstrap/Spinner';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import CurrencyInput from '../_components/CurrencyInput.tsx'

const billTo = [
  { value: 'C', label: 'Client', key: 1 },
  { value: 'T', label: 'Tek', key: 2 },
  { value: 'P', label: 'Personal', key: 3 }
]

export { UploadReceipt };

function UploadReceipt() {
  const [isClearable, setIsClearable] = useState(true);

 // const [payeeOptions, setPayeeOptions] = useState(payee);
  const [billOption, selectBillTo] = useState();
  const [clientOption, setClient] = useState();
  const [creditOption, setCreditOption] = useState("false");
  const [clientExpTaskLevel, setClientExpTaskLevel] = useState();
  const [subTaskId, setSubTaskId] = useState();
  const [projectOption, setProject] = useState();
  const [submitCheck, setSubmit] = useState(false);
  let [loading, setLoading] = useState(false);
  const [taskOption, setTask] = useState();
  const [saveError, setError] = useState('');
 
  const [images, setImages] = React.useState([]);
  const [imagesMobile, setMobileImages] = useState(null);
  const { user: authUser } = useSelector(x => x.auth);
  const [response, setResponse] = useState(false);
  // const saveError = useSelector(x => x.saveReceipts.error);
  const vendorRef = useRef();
  const ccRef = useRef();
  const userRef = useRef();
  const dateRef = useRef();
  // const amountRef = useRef();
  const descRef = useRef();
  const ticketRef = useRef();
  const billToRef = useRef();
  const clientRef = useRef();
  const projectsRef = useRef();
  const taskRef = useRef();
  const subtaskRef = useRef();
  // const subRef = useRef();
  const radioRef = useRef();
  const personRef = useRef();
  
  const saveSuccess = useSelector(x => x.saveReceipts.success);
  if (!authUser) {
    return <Navigate to="/login" state={{ from: history.location }} />
}
  const userDefaultValue = {
    user_active: authUser.user.user_active,
    user_fname: authUser.user.user_fname,
    user_id: authUser.user.user_id,
    user_lname: authUser.user.user_lname,
    user_mname: authUser.user.user_mname
  }
 

  
  const { payee } = useSelector(x => x.payee);
  const [userValue, setUserValue] = useState(userDefaultValue.user_id);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const current = new Date();
  const Todaysdate = `${current.getFullYear()}-${(current.getMonth() + 1).toString().padStart(2, '0')}-${current.getDate().toString().padStart(2, '0')}`;
  // const Todaysdate = `${current.getFullYear()}-${(current.getMonth() > 9 ? (current.getMonth() + 1) : '0'+( current.getMonth() + 1))}-${current.getDate() > 9 ? current.getDate() : '0' + current.getDate() }`;
  const [currentDate, setDate] = useState(Todaysdate);
  const [ticketNumber, setTicketNumber] = useState('');
  const [amountValue, setAmount] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { users } = useSelector(x => x.users);

  const { clients } = useSelector(x => x.clients);
  let { projects } = useSelector(x => x.projects);
  let { tasks } = useSelector(x => x.tasks);
  let { subtasks } = useSelector(x => x.subtasks);
  let { purchaseType } = useSelector(x => x.purchaseType);

  const targetDiv = document.getElementById("image-upload")?.getElementsByTagName("input")[0];
  targetDiv?.setAttribute('required', 'required');
 if(images.length > 0){
   targetDiv?.removeAttribute('required');
 }


//  const hidedivmob = document.getElementById("item-image-mob");
const dateField = document.getElementById("date");
const fieldEle = document.getElementsByClassName("field-element");
//  const uploadButton = document.getElementById("iphoneId");
//  const mobileFileUploadIphone = document.getElementById("upload_mobile");

// if (/iPhone/i.test(navigator.userAgent)){
//  // hidedivmob?.style.setProperty('display', 'none')
//  dateField?.style.setProperty('padding-right', '58%')
//  fieldEle?.style.setProperty('width', '80%')
//  // uploadButton?.style.setProperty('margin-right', '15px')
//  // mobileFileUploadIphone?.style.setProperty('left', '24px')
//  }
 const { creditcards } = useSelector(x => x.creditcards);
 let creditDefaultOption = {cc_active: '', cc_id:'', cc_name_on_card: '', cc_nbr:'Select...',cc_user_id:''}
 
 if (localStorage.getItem('ccValue')) {
     creditDefaultOption  = JSON.parse(localStorage.getItem('ccValue'));
 }

 const [ccIdValue, setCCIdValue] = useState(creditDefaultOption.cc_id);


useEffect(() => {

  
   const targetDiv = document.getElementById("image-upload")?.getElementsByTagName("input")[0];
   targetDiv?.setAttribute('required', 'required');
    if(windowSize.innerWidth <= 1040) {
      targetDiv?.setAttribute('accept', 'image/*');
      // targetDiv?.setAttribute('capture', 'environment');
      
     }
     
    dispatch(userActions.getAll());
    dispatch(creditcardActions.getAll());
    dispatch(clientsActions.getAll());
    dispatch(payeeActions.getAll());
    dispatch(purchaseTypeActions.getAll());
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
   
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };


  }, []);


  
  const maxNumber = 5;

  //image upload
  const onChange = (imageList, addUpdateIndex) => {   
  setImages(imageList);   
  };


  //Bill dropdown
  const selectBill = (e) => {
    if (e) {
      selectBillTo(e.key);
      onChangeGetValue({ target: { value: e?.value, name: 'cc_trans_bill_to' } })
      if (e.key == 1) {
         inputField.cc_trans_purchase_type_id = '';
         inputField.cc_trans_bill_to_user_id = '';
      
       // inputField.projects = '';
      }
      else if (e.key == 2) {
        inputField.cc_trans_bill_to_client_id = '';
        inputField.cc_trans_bill_to_user_id = '';
         inputField.cc_trans_bill_to_project_id = '';
         inputField.cc_trans_bill_to_ts_task_id = '';
        // inputField.subtasks = '';

      }
      else if (e.key == 3) {
        inputField.cc_trans_bill_to_client_id = '';
        inputField.cc_trans_bill_to_project_id = '';
        inputField.cc_trans_bill_to_ts_task_id = '';
       
         inputField.cc_trans_purchase_type_id = '';
        // inputField.tasks = '';
        // inputField.subtasks = '';

      }
    } else {
      selectBillTo(undefined)
    }
  };
  const selectClient = (e) => {
    if (e) {
      
  setClientExpTaskLevel(e.client_exp_at_task_level);
      const param = e.client_id;
      dispatch(projectsActions.getAllById({param}));
      setClient(e.client_id);
      if (billOption == 1) {
        onChangeGetValue({ target: { value: e?.client_id, name: 'cc_trans_bill_to_client_id' } })
      }
      if (e.client_exp_at_task_level == null || e.client_exp_at_task_level == 0){
        inputField.cc_trans_bill_to_project_id = '';
      inputField.cc_trans_bill_to_ts_task_id = '';
      }
    } else {
      projects = [];
      setProject(undefined)
      tasks=[];
      subtasks = [];
      setClient(undefined);
      setTask(undefined);
      inputField.cc_trans_bill_to_project_id = '';
      inputField.cc_trans_bill_to_ts_task_id = '';
    }
  };
 
  const selectProject = (e) => {
    if (e) {
      const param = e.project_id;
      dispatch(tasksActions.getAllById({param}));
      if (billOption == 1) {
        setProject(e.project_id)
        onChangeGetValue({ target: { value: e?.project_id, name: 'cc_trans_bill_to_project_id' } })
      }
    }else {
      tasks=[];
      subtasks = [];
      setProject(undefined);
      setTask(undefined)
    }
  };
  const selectTasks = (e) =>{
    if (e) {
      const param = e.ts_task_id;
      setTask(e.ts_task_id)      
      dispatch(subtasksActions.getAllById({param}));
     
    }else {
      subtasks=[];
      setTask(undefined)
    }
  };
  const selectSubTasks = (e) =>{
    if (e) {     
      setSubTaskId(e.ts_task_id);     
    }
  };
  const selectPurchaseType = (e) => {
    if (e) {
      if (billOption == 2) {
        onChangeGetValue({ target: { value: e?.cc_trans_purchase_type_id, name: 'cc_trans_purchase_type_id' } })
      }
    }
  };
  const selectPersonal = (e) => {
    if (e) {
      if (billOption == 3) {
        onChangeGetValue({ target: { value: e?.user_id, name: 'cc_trans_bill_to_user_id' } })
      }
    }
  };

  //filled values
  const onChangeGetValue = e => {
    const { name, value } = e.target;
    setInputField(prevState => ({
      ...prevState,
      [name]: value,
    }));
    if (name == 'cc_trans_dt') {
      setDate(value);
    }
    if (name == 'used_by') {
      setUserValue(value);
    }
    if(name == 'cc_nbr'){
      setCCIdValue(value)
    }
    if(name=='cc_trans_amt'){
      inputField.cc_trans_bill_amt = value
    }
   
  };

  const onChangeticketNumber = (e) => {
    const value = e.target.value?.replace(/\D/g, "");
    setTicketNumber(value);
    inputField.cc_trans_ticket_number = value;
  };
 
  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }
 
  const [inputField, setInputField] = useState({
cc_id:ccIdValue,
cc_trans_dt:currentDate,
cc_trans_vendor_id:'',
cc_trans_amt:null,
cc_trans_memo:'',
cc_trans_status:'S',
cc_trans_notes: '',
cc_trans_user_id:userValue,
cc_trans_uploaded_by:userValue,
cc_qb_account_id: null,
cc_trans_qb_status: 'P',
cc_trans_bill_to:'',
cc_trans_bill_amt:null,
cc_trans_bill_to_client_id:'',
cc_trans_bill_to_user_id:'',
cc_trans_bill_status:'P',
client_inv_id: null,
cc_trans_ticket_number: '',
cc_trans_reviewed:0,
cc_trans_bill_to_ts_task_id:'',
credit: "false",
cc_trans_purchase_type_id:'',
cc_trans_uploaded_bys:userDefaultValue.user_id,
cc_trans_bill_to_project_id: '',

  });
  // const handleCheckBox = (e) => {
  //   let isChecked = e.target.checked;
  //     if(isChecked) {
  //       inputField.cc_trans_status = 'N';
  //     } else {
  //       inputField.cc_trans_status = 'S';

  //     }
  //  }
   const radioOptionChange = (e) => {
   const value = e.target.value;
   inputField.credit=value;
    setCreditOption(value)
   }
  
  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmit(true)
    if(projects.length <= 0){
      //  inputField.projects = '';
      //  inputField.cc = '';
      //  inputField.subtasks= '';
    }
    if(clientExpTaskLevel == 0 || clientExpTaskLevel == null) {
      inputField.cc_trans_bill_to_ts_task_id = null;
    } else if(clientExpTaskLevel == 1) {
          if(subTaskId) {
            inputField.cc_trans_bill_to_ts_task_id = subTaskId;

          } else if(taskOption) {
            inputField.cc_trans_bill_to_ts_task_id = taskOption;

          }
    }
    //  inputField.cc_trans_bill_amt = inputField.cc_trans_amt; //set the same amount
    if ( inputField.cc_trans_amt != 0 && inputField.cc_id != '') {
      setLoading(true);
      apiCall();
    }

  }
//   function apiCall() {
//     document.body.style.opacity=0.5;
//     const baseUrl = `${process.env.REACT_APP_API_URL}/user/`;
//     fetch(baseUrl + 'SaveReceipts', {
//       method: 'POST',
//       headers: {
         
//             'Content-Type': 'application/json',
//           'Authorization': 'Bearer ' + authUser.jwtToken
//       },
//       body: JSON.stringify(inputField)
//  }).then((response) =>  {
  
//       setLoading(true);
//       if (response.ok) {
     
//         var formData = new FormData();
//         const fileData = images[0].file;
//         formData.append("files", fileData);
//         formData.append("cc_trans_id", 0);
     
//         fetch(baseUrl + 'UploadFile', {
//           method: 'POST',
//           headers: {
//               'Accept': 'application/json',             
//               'Authorization': 'Bearer ' + authUser.jwtToken
//           },
//           body: formData
//      }).then((response) =>  {
      
//        setLoading(true);
     
     
//       if(response.ok) {
//         window.scrollTo(0, 0);
//         setResponse(true); 
//         setTimeout(() => {
//           setResponse(false);
         
//           window.location.reload();
//           document.body.style.opacity=1; 
//         }, 2000);
//         setError('');
//         setSubmit(false);
//         setLoading(false);       
//       } else {
//         setError(response.statusText);
//         setLoading(false);   
//       } 
                  
//      });
//       } else {
//         setError(response.statusText);       
//         setLoading(false);   

//       }
//     });
//   }
function apiCall() {
  document.body.style.opacity = 0.5;
  const baseUrl = `${process.env.REACT_APP_API_URL}/user/`;

 
  var formData = new FormData();
  for (const key in inputField) {
   
    if (Object.prototype.hasOwnProperty.call(inputField, key)) {
      formData.append(key, inputField[key]);
    }
  }


  const fileData = images[0].file;
  formData.append("files", fileData);

  
  formData.append("cc_trans_id", 0);

  fetch(baseUrl + 'UploadFile', {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + authUser.jwtToken,
      'Accept': 'application/json',
    },
    body: formData,
  }).then((response) => {
    setLoading(true);
    if (response.ok) {
      window.scrollTo(0, 0);
      setResponse(true);
      setTimeout(() => {
        setResponse(false);
       window.location.reload();
        document.body.style.opacity = 1;
      }, 2000);
      setError('');
      setSubmit(false);
      setLoading(false);
    } else {
      setError(response.statusText);
      setLoading(false);
    }
  });
}


  const getOptionLabel = (option) => {
    if (option.cc_active === false) {
      return (
        <div className='gray-bg common-bg'> 
          {option.cc_nbr}
        </div>
      );
    } else  if (option.cc_active === true){
      return (
        <div className='white-bg common-bg'> 
          {option.cc_nbr}
        </div>
      );
    } else {
      return (
        <div className='gray-bg-select'> 
          {option.cc_nbr}
        </div>
      );
    }
  }
  return (
    <>

      <div className="App">
        <div className="upload-receipts">
          
<div className='dash-icon'>
  <div></div>
<h6 className='dash-title upload-receipt-title'>TEK CC RECEIPT</h6>
<OverlayTrigger
                                key='bottom'
                                placement='bottom'
                                overlay={
                                    <Tooltip id='tooltip-bottom'>
                                      Tek CC Receipt Dashboard
                                    </Tooltip>
                                }
                               >
                               
<a href="/Dashboard" className='dashboard-icon-upload'><img src={'/assets/images/dashboard2.png'} /></a>
                               
                              
                            </OverlayTrigger>

</div>
        {loading &&
            <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner> }
                {response &&
                  <div className='flex toast-msg'>
                <div className="alert alert-success" role="alert">
                Receipt uploaded successfully
               </div>
               </div>}
                {/* {!response &&
              <div className="alert alert-success green mt-3 mb-0">Receipt uploaded successfully</div>
              
          } */}
           
          <main>
            <div className="container">
              <div className="">
                <div className="receipts-form">
                  <form onSubmit={handleSubmit}>

                    {/* {radio} */}
                    <div className="field-group">
                      <div className="field-element"></div>
                      <div className="field-element flex">
                      <div className="field">
                      <input type="radio" id="charge" ref={radioRef} value="false" checked={creditOption == "false"} name="credit_option" onChange={radioOptionChange}/>
<label htmlFor="charge">CHARGE </label> </div>
<div className="field credit">
 <input type="radio" id="credit" name="credit_option" ref={radioRef} value="true" checked={creditOption === "true"}  onChange={radioOptionChange}/>
 <label htmlFor="credit">CREDIT</label></div>

                      </div>
                      <div className="field-element"></div>
                      </div>
                        {/* {radio} */}
                    <div className="field-group">
                      <div className="field-element">
                        <div className="field cc-dropdown">
                          <div className="label">Credit Card<span className="mandatory-field">* </span></div>

                          <Select options={creditcards}
                            isClearable={isClearable}
                            // getOptionLabel={e => e.cc_nbr}
                            getOptionValue={e => e.cc_id}                        
                            defaultValue={creditDefaultOption}
                             isSearchable={false}
                            className="form-custom-class cc-class"
                            classNamePrefix="form-custom-class-prefix" name="cc_id"
                            required
                            ref={ccRef}
                            getOptionLabel={getOptionLabel}
                            onChange={data => onChangeGetValue({ target: { value: data?.cc_id, name: 'cc_id' } })}
                          />
                          {inputField.cc_id == '' && submitCheck &&
                          <span className="error-msg">Please select a card</span>}
                        </div>
                      </div>

                      <div className="field-element">
                        <div className="field">
                          <div className="label">Used By<span className="mandatory-field">* </span></div>

                          <Select options={users}
                            isClearable={isClearable}
                            getOptionLabel={e => e.user_id}
                            getOptionValue={e => e.user_id}
                            defaultValue={userDefaultValue}
                            className="form-custom-class"
                            classNamePrefix="form-custom-class-prefix" name="cc_trans_user_id"
                            required
                            ref={userRef}
                            onChange={data => onChangeGetValue({ target: { value: data?.user_id, name: 'cc_trans_user_id' } })}

                          />

                        </div>
                      </div>
                      <div className="field-element">
                        <div className="field">
                          <label htmlFor="cc_trans_dt" className="label">Transaction Date<span className="mandatory-field">* </span></label>
                          <input name="cc_trans_dt" ref={dateRef} id="date"  type="date" min="2022-12-27" onChange={onChangeGetValue} required max={Todaysdate}/>
                        </div>
                      </div>
                    </div>
                    <div className="field-group second-section" key="payee">
                      <div className="field-element">
                        <div className="field">
                          <label htmlFor="cc_trans_vendor_id" className="label">Vendor<span className="mandatory-field">* </span></label>
{/* 
                          <CreatableSelect
                            isClearable
                            // isDisabled={isLoading}
                            // isLoading={isLoading}
                            //  onChange={(newValue) => setPayee(newValue)}
                            // onCreateOption={handleCreate}
                            options={payee}
                            getOptionLabel={e => e.cc_trans_vendor_name}
                            getOptionValue={e => e.cc_trans_vendor_id}
                            //  value={payeeOption}
                            className="form-custom-class"
                            classNamePrefix="form-custom-class-prefix"
                            name="payee"
                            required
                            onChange={data => onChangeGetValue({ target: { value: data?.cc_trans_vendor_id, name: 'payee' } })}
                          /> */}
                            <Select options={payee}
                            ref={vendorRef}
                            isClearable={isClearable}
                            getOptionLabel={e => e.cc_trans_vendor_name}
                            getOptionValue={e => e.cc_trans_vendor_id}
                            //  defaultValue={cc_trans_vendor_id}
                            className="form-custom-class"
                            classNamePrefix="form-custom-class-prefix" name="cc_trans_vendor_id"
                            required
                            onChange={data => onChangeGetValue({ target: { value: data?.cc_trans_vendor_id, name: 'cc_trans_vendor_id' } })}
                          

                          />
                        </div>

                      </div>
                      <div className="field-element">
                        <div className="field">
                          <label htmlFor="cc_trans_amt" className="label">Amount<span className="mandatory-field">* </span></label>

                          <CurrencyInput
                            id="input-example"
                            name="cc_trans_amt"
                            decimalsLimit={2}
                            prefix="$ "
                            allowDecimals
                            placeholder="$ 0.00"
                            allowNegativeValue={false}
                            required                            
                            // value={amountValue}
                            // ref={amountRef}
                            key='amount_key'
                            
                            onValueChange={(value, name) => onChangeGetValue({ target: { value: value, name: 'cc_trans_amt' } })}
                          />
                            {/* <CurrencyInput placeholder="$ 0.00" id="input-example" name="cc_trans_amt" required type="text" onChange={amountChange}/> */}
                            {/* <CurrencyInput
          max={100000000}
          onValueChange={(value, name) => onChangeGetValue({ target: { value: value, name: 'cc_trans_amt' } })}
          // style={{ textAlign: 'right' }}
          value={amountValue}
        /> */}{inputField.cc_trans_amt == 0 &&
                          <span className="error-msg">Please enter &gt; 0 value</span>}
                        </div>
                      </div>
                      <div className="field-element">
                        <label htmlFor="cc_trans_memo" className="label">Description<span className="mandatory-field">* </span></label>
                        <div className="field">
                          <input name="cc_trans_memo" id="memo" type="text" required ref={descRef} onChange={onChangeGetValue} />
                        </div>
                      </div>

                      <div className="field-element">
                        <label htmlFor="cc_trans_ticket_number" className="label">Ticket Number</label>
                        <div className="field">
                          <input name="cc_trans_ticket_number" id="ticketNumber" ref={ticketRef} value={ticketNumber} type="text" onChange={onChangeticketNumber} />
                        </div>
                      </div>
                    </div>

                    <div className="field-group third-section">
                      <div className="field-element">
                        <div className="field">
                          <label htmlFor="memo" className="label">Bill To<span className="mandatory-field">* </span></label>
                          <Select options={billTo}
                            onChange={(e) => selectBill(e)}
                            isClearable={isClearable}
                            className="form-custom-class"
                            classNamePrefix="form-custom-class-prefix"
                            name="cc_trans_bill_to" 
                            required
                            ref={billToRef}

                          />
                        </div></div>
                      {billOption == 1 ? (
                        <div className="field-element" key="client">
                          <div className="field">
                            <label htmlFor="cc_trans_bill_to_client_id" className="label">Client<span className="mandatory-field">* </span></label>
                            <Select options={clients}
                              isClearable={isClearable}
                              getOptionLabel={e => e.client_name}
                              getOptionValue={e => e.client_id}
                              onChange={(e) => selectClient(e)}
                              className="form-custom-class"
                              classNamePrefix="form-custom-class-prefix"
                              name="cc_trans_bill_to_client_id"
                              ref={clientRef}
                              required

                            />
                          </div>
                        </div>) :
                         billOption == 2 ? (
                          <div className="field-element" key="department">
                            <div className="field">
                              <label htmlFor="memo" className="label">Purchase Type</label>
                              <Select options={purchaseType}
                                isClearable={isClearable}
                                getOptionLabel={e => e.cc_trans_purchase_type}
                                getOptionValue={e => e.cc_trans_purchase_type_id}
                                onChange={(e) => selectPurchaseType(e)}
                                className="form-custom-class"
                                classNamePrefix="form-custom-class-prefix"
                                name="cc_trans_purchase_type_id"
                                

                              />
                            </div>
                          </div>) : 
                          billOption == 3 ?
                        (

                          <div className="field-element" key="personal">
                            <div className="field">
                              <label htmlFor="cc_trans_bill_to_user_id" className="label">Name<span className="mandatory-field">* </span></label>
                              <Select options={users}
                                isClearable={isClearable}
                                getOptionLabel={e => e.user_id}
                                getOptionValue={e => e.user_id}
                                onChange={(e) => selectPersonal(e)}
                                className="form-custom-class"
                                classNamePrefix="form-custom-class-prefix"
                                name="cc_trans_bill_to_user_id"
                                ref={personRef}
                                required

                              />
                            </div>
                          </div>) : (<div>

                          </div>)}
                      {/* client options*/}
                      
                      {(billOption == 1 && projects.length > 0 && clientOption && (clientExpTaskLevel != null && clientExpTaskLevel != 0)) ? (

                        <div className="field-element" key="Projects">
                          <div className="field">
                            <label htmlFor="memo" className="label">Project<span className="mandatory-field">* </span></label>
                           
                            <Select options={projects}
                              isClearable={isClearable}
                              getOptionLabel={e => e.project_name}
                                getOptionValue={e => e.project_id}
                              onChange={(e) => selectProject(e)}
                              className="form-custom-class"
                              classNamePrefix="form-custom-class-prefix"
                              name="cc_trans_bill_to_project_id"
                              ref={projectsRef}
                              required
                            /> 
                          </div>
                        </div>) : (<div className="field-element"></div>)}

                      {/* client options*/}
                    </div>

                    {/* {new dropdown} */}
                   
                    <div className="field-group">
                    {(billOption == 1 && tasks.length > 0 && projectOption && clientOption && projects.length > 0 && (clientExpTaskLevel != null && clientExpTaskLevel != 0)) ? (
                      <div className="field-element">
                        <div className="field">
                          <label htmlFor="memo" className="label">Task<span className="mandatory-field">* </span></label>
                          <Select options={tasks}
                            onChange={(e) => selectTasks(e)}
                            isClearable={isClearable}
                            getOptionLabel={e => e.task_name}
                            getOptionValue={e => e.ts_task_id}
                            className="form-custom-class"
                            classNamePrefix="form-custom-class-prefix"
                            name="bill_to" 
                            ref={taskRef}
                            required

                          />
                        </div></div>) : <div></div>}

                        {( billOption == 1 && subtasks.length > 0 && taskOption && projectOption && clientOption && projects.length > 0) && (clientExpTaskLevel != null && clientExpTaskLevel != 0)  ? (
                        <div className="field-element">
                        <div className="field">
                          <label htmlFor="memo" className="label">Sub Task<span className="mandatory-field">* </span></label>
                          <Select options={subtasks}
                            onChange={(e) => selectSubTasks(e)}
                            isClearable={isClearable}
                            getOptionLabel={e => e.task_name}
                            getOptionValue={e => e.ts_task_id}
                            className="form-custom-class"
                            classNamePrefix="form-custom-class-prefix"
                            name="bill_to" 
                            ref={subtaskRef}
                            required

                          />
                        </div></div>):(
                        <div className="field-element"></div>)}
                        
                        <div className="field-element"></div>
                        </div>
{/* {tasks end} */}

                    {/* <div>conditional</div> */}
                    {/* {upload}  */}
<div className='button-message'>
                    <div className="upload-images-div">
               
               <div className="desk-view-r">
                 <div className="field-group">
                   
                   <div className="field">
                     <div className="button-wrap" id='image-upload'>
                       {/* <label className="button" htmlFor="upload">Upload File</label> */}
                       {/* <input id="upload" type="file" /> */}
                       {/* image upload */}
                       <ImageUploading                         
                         value={images}
                         onChange={onChange}
                         maxNumber={maxNumber}                         
                         dataURLKey="data_url"
                         acceptType={['jpg', 'jpeg', 'pdf', 'png', 'html']}
                         allowNonImageType={true} 
                                         
                       >
                         {({
                           imageList,
                           onImageUpload,
                           isDragging,
                           dragProps,
                         }) => (
                           // write your building UI
                           <div className="upload__image-wrapper">
                             <div className='upload-button'
                               style={isDragging ? { color: 'red' } : undefined}
                               onClick={onImageUpload}
                               {...dragProps} 
                             >
                               {/* PDF Receipt */}
                              <span className='mobile-view'><img src={'/assets/images/camera-small.png'}/> *</span> <span className='desk-view'>ATTACH TEK CC RECEIPT *</span>
                             </div>
                             &nbsp;
                              
                             {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
                             {imageList.map((image, index) => (

                               <div key={index} className="image-item">
                                 {image['file']?.type == "application/pdf" ? (
                                   <div><img src={'assets/images/PDF_file_icon.png'} className="App-logo" alt="logo" />

                                     {/* <div className="image-item__btn-wrapper">
                                       <button onClick={() => onImageRemove(index)}>&times;</button>
                                     </div> */}
                                   </div>) : image['file']?.type == "text/html" ? 
                                   (<div><img src={'assets/images/html_icon.png'} className="App-logo" alt="logo" />
                                   </div>) : (
                                   <div>
                                     <img src={image['data_url']} alt="" width="100" />
                                     {/* <div className="image-item__btn-wrapper">

                                       <button onClick={() => onImageRemove(index)}>&times;</button>
                                     </div> */}
                                   </div>)}
                               </div>
                             ))}
                           </div>
                         )}
                       </ImageUploading>
                       {/* image upload */}
                      
                     </div>
                   </div>
                 </div>
               </div>
           {/* {upload end} */}
         

                      <div className="field-group">
                        <div className="field">
               <i className='file-format'>Image, PDF &amp; HTML files allowed</i>      
                          <div className='receive-save-wrapper'>
                            <div className="sign-btn flex">
                            {/* {loading && <span className="spinner-border spinner-border-sm mr-1"></span>} */}
                              <button type="submit" className="btn btn-primary receipt-save">
                              Submit</button>
{/* <div>
                              <input type="checkbox" id="status" ref={subRef}  onChange={handleCheckBox} name="cc_trans_status" className='receipt-check'></input>
                              <label htmlFor="status">Do not send to accounting yet</label></div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                    <div className="field-group">
                      {/* {response &&
              <div className="alert alert-success green mt-3 mb-0">Receipt uploaded successfully</div>
              
          } */}
                            <div className="error-msg alert alert-success mt-3 mb-0">{saveError}</div>
                      </div>
                    </div>
                    
                  </form>
                </div>
              </div>
            </div>
          </main>
  {/* )} */}
 
        </div>
      </div>
     
    </>
  );
}


