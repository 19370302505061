import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '../_helpers';

// create slice

const name = 'DeleteRecord';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const deleteRecordActions = { ...slice.actions, ...extraActions };
export const deleteRecordReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        DeleteRecord: {}
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/Dashboard`;

    return {
        deleteRecord: deleteRecord()
    };    

    function deleteRecord() {
       
            return createAsyncThunk(
                `${name}/deleteRecord`,
                async (transId)  => await fetchWrapper.delete(`${baseUrl}/DeleteReceipt/${transId}`)
            );
    }
}

function createExtraReducers() {
    return {
        ...deleteRecord()
    };
    
    function deleteRecord() {
       
        var { pending, fulfilled, rejected } = extraActions.deleteRecord;
        return {
            [pending]: (state) => {
                state.DeleteRecord = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.success = action.payload;
              
            },
            [rejected]: (state, action) => {
                state.DeleteRecord = { error: action.error };
            }
        };
    }
}
