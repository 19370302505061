import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { authActions } from '../_store';

export { IdleTimeout };
const IdleTimeout = ({ timerDuration }) => {
  const dispatch = useDispatch();
  const [idleTimer, setIdleTimer] = useState(null);

  useEffect(() => {
    let timer;

    const resetIdleTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(logout, timerDuration);
      setIdleTimer(timer);
    };

    const handleUserActivity = () => {
      resetIdleTimer();
    };

    const logout = () => dispatch(authActions.logout());

    resetIdleTimer();

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    return () => {
      clearTimeout(timer);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
    };
  }, [timerDuration, dispatch]);

//   return null; // Or any other JSX you want to render
};


