import React, { useEffect, useState } from 'react';
import './MissingReceiptsDashboard.scss';
import { FormModal } from '../../_components/FormModal';
import { FilterModal } from '../../_components/FilterModal';
import { MissingReceiptsExportModal } from '../../_components/MissingReceiptsExportModal';
import { missingReceiptsDashboardActions } from "../../_store";
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Spinner from 'react-bootstrap/Spinner';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";
import { Navigate } from 'react-router-dom';
import Pagination from '../../_components/PaginationRange';
import { formatValue } from 'react-currency-input-field';


export { MissingReceiptsDashboard }
function MissingReceiptsDashboard() {
  const { user: authUser } = useSelector(x => x.auth);
  if (!authUser) {
    return <Navigate to="/login" state={{ from: history.location }} />
  }
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = React.useState(false);
  const [modalData, setModalData] = React.useState();
  const [modalFilterShow, setFilterModalShow] = React.useState(false);
  const [modalExportShow, setModalExportShow] = React.useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalAmount, setTotalAmount] = useState();
  const [recordsPerPage, setRecordsPerPage] = useState(25);
  const [selectedCount, setSelectedCount] = useState(0);

  let dashboard = useSelector(x => x.dashboard);
  let dashboardList = [];
  dashboardList = dashboard.dashboard.getReceipts;
  const dashboardArgs = dashboard.dashboardArgs;
  const apipagination = dashboard.dashboard.pagination;
  const getUserCount = dashboard.dashboard.getUserCount;

  let Filterobj = {
    cc_id: '',
    cc_trans_from_dt: null,
    cc_trans_to_dt: null,
    cc_trans_amt: '',
    sortColumn: 'cc_trans_dt',
    sortOrder: 'DESC',
    pageNumber: currentPage,
    pageSize: recordsPerPage,
    exportExcel: false,
    isCharge: null   
  }

  let filterObjectSearchValues = {};


  useEffect(() => {
    const storedFilters = localStorage.getItem('missingReceiptsFilterValues');
    if (storedFilters) {
      const localStorageFilerObj = JSON.parse(storedFilters);
      // Update the local storage object if necessary
      localStorageFilerObj.pageNumber = 1;
      localStorageFilerObj.pageSize = 25;
      localStorage.setItem('missingReceiptsFilterValues', JSON.stringify(localStorageFilerObj));

      // Dispatch the action with the original object
      dispatch(missingReceiptsDashboardActions.getAll(localStorageFilerObj));
    } else {
      dispatch(missingReceiptsDashboardActions.getAll(Filterobj));
      localStorage.setItem('missingReceiptsFilterValues', JSON.stringify(Filterobj));
      Filterobj.pageNumber = 1;
      Filterobj.pageSize = 25;
    }
  }, [dispatch])

  useEffect(() => {

    var sum = 0;
    for (var i = 0; i < dashboardList?.length; i++) {
      if (dashboardList[i].cc_trans_amt != null && dashboardList[i].cc_trans_amt !== '') {
        const cleanedAmount = dashboardList[i].cc_trans_amt
          .replace(/,/g, '')
          .replace(/\./g, '.');
        const parsedAmount = parseFloat(cleanedAmount);
        if (!isNaN(parsedAmount)) {
          sum += parsedAmount;
        }

      }
    }

    setTotalAmount(sum.toString());
    const storedFilters = localStorage.getItem('missingReceiptsFilterValues');
    const localStorageFilerObj = JSON.parse(storedFilters);
    const { pageNumber, pageSize, sortColumn, sortOrder, exportExcel, ...localStorageFilterdObj } = localStorageFilerObj;
    const countPropertiesWithValue = (obj) => {
      let count = 0;
      let dateFieldCounted = false;
      for (const [key, value] of Object.entries(obj)) {
        if ((key === 'cc_trans_from_dt' || key === 'cc_trans_to_dt')) {
          if (!dateFieldCounted && (obj['cc_trans_from_dt'] || obj['cc_trans_to_dt'])) {
            count += 1;
            dateFieldCounted = true;
          }
        } else if (value !== null && value !== "") {
          count += 1;
        }
      }
      return count;
    };

    const count = countPropertiesWithValue(localStorageFilterdObj);
  
    setSelectedCount(count);

  }, [dashboardList]);


  let formattedValue1 = 0;
  if (totalAmount) {
    formattedValue1 = formatValue({
      value: totalAmount,
      groupSeparator: ',',
      decimalSeparator: '.',
      decimalScale: 2,
    });
  }

  useEffect(() => {
    if (localStorage.getItem('missingReceiptsFilterValues') != null) {
      filterObjectSearchValues = JSON.parse(localStorage.getItem('missingReceiptsFilterValues'));
      filterObjectSearchValues.pageNumber = currentPage;
      filterObjectSearchValues.pageSize = recordsPerPage;
      dispatch(missingReceiptsDashboardActions.getAll(filterObjectSearchValues));
      localStorage.setItem('missingReceiptsFilterValues', JSON.stringify(filterObjectSearchValues));
    } else {
      dispatch(missingReceiptsDashboardActions.getAll(Filterobj));
    }
  }, [currentPage, recordsPerPage]);


  const columns = [
    { dataField: 'cc_nbr', text: 'CREDIT CARD', sort: true, order: 'ASC' },
    { dataField: 'cc_trans_dt', text: 'DATE', sort: true, order: 'default' },
    { dataField: 'cc_trans_amt', text: 'AMOUNT', sort: true, order: 'default' },
    { dataField: 'cc_trans_desc', text: 'DESCRIPTION', sort: true, order: 'default' },
  ];


  function sortClicked(columnObj) {
    if (columnObj.dataField == dashboardArgs?.sortColumn) {
      const orderAPI = dashboardArgs.sortOrder === 'DESC'
        ? 'ASC'
        : 'DESC';
      if (localStorage.getItem('missingReceiptsFilterValues')) {
        filterObjectSearchValues = JSON.parse(localStorage.getItem('missingReceiptsFilterValues'));
        filterObjectSearchValues.sortColumn = columnObj.dataField;
        filterObjectSearchValues.sortOrder = orderAPI;
        localStorage.setItem('missingReceiptsFilterValues', JSON.stringify(filterObjectSearchValues));
      }
    } else {
      if (columnObj.sort === false) {
        return;
      }
      for (let i = 0; i < columns.length; i++) {
        if (columns[i].dataField !== columnObj.dataField) {
          columns[i].order = 'default';
        }
        else {
          columns[i].order =
            columns[i].order === 'default' ||
              columns[i].order === 'DESC'
              ? 'ASC'
              : 'DESC';

          if (localStorage.getItem('missingReceiptsFilterValues')) {
            filterObjectSearchValues = JSON.parse(localStorage.getItem('missingReceiptsFilterValues'));
            filterObjectSearchValues.sortColumn = columnObj.dataField;
            filterObjectSearchValues.sortOrder = columns[i].order;
            localStorage.setItem('missingReceiptsFilterValues', JSON.stringify(filterObjectSearchValues));
          }
        }
      }
    }
    const copyFilterobj = Object.assign({}, filterObjectSearchValues);
    dispatch(missingReceiptsDashboardActions.getAll(copyFilterobj));
    copyFilterobj.pageNumber = 1;
    copyFilterobj.pageSize = 25;
  }
  const [fullscreen, setFullscreen] = React.useState(true);
  function openEditOverlay(rowData) {
    setModalShow(true);
    setModalData(rowData)
    setFullscreen(true);
  }


  return (
    <>
      <FormModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        modaldata={modalData}
        fullscreen={fullscreen}
        missingreceipts={true}
      />
      <FilterModal
        show={modalFilterShow}
        onHide={() => setFilterModalShow(false)}
        filterobj={Filterobj}
        setcurrentpage={setCurrentPage}
        setrecordsperpage={setRecordsPerPage}
        missingreceipts={true}
        className="missing-modal"
      />
      <MissingReceiptsExportModal
        show={modalExportShow}
        onHide={() => setModalExportShow(false)}
      />

      <div className="missing">
        {!dashboardList && (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}

        <main>
          <div className="container">
            <div className="filter-container">
              <div className="dash-icon">
                <div></div>
                <h6 className="dash-title dash-title-dashboard">
                  MISSING RECEIPTS DASHBOARD
                </h6>
                <div className="dash-trigger-icons">
                  <OverlayTrigger
                    key="tooltip-exports"
                    placement="bottom"
                    overlay={
                      <Tooltip id="tooltip-export">Export Files</Tooltip>
                    }
                  >
                    <a onClick={() => setModalExportShow(true)}>
                      <img src={"/assets/images/export.jpg"} />
                    </a>
                  </OverlayTrigger>

                  <OverlayTrigger
                    key="filter"
                    placement="bottom"
                    overlay={<Tooltip id="tooltip-filter">Filter</Tooltip>}
                  >
                    <a onClick={() => setFilterModalShow(true)}>
                      {" "}
                      <img src={"/assets/icons/filter.svg"} />{selectedCount > 0 && <span className='filter-count'>{`${selectedCount}`}</span>}
                    </a>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className="">
              <div className="userlist-missing-receipts">
                <ul>
                  {getUserCount?.map((listValue, index) => {
                    return (
                      <li key={index}>
                        <div className='userlist-details'>
                          <div className='user-name'>{listValue.user_id}</div>  <div className='total-count bold'>{listValue.count}</div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="login-form ">
                <div className="table-container table-responsive">
                  <table className="credit-table-container table-layout">
                    <thead>
                      <tr>
                        {columns.map((columnObj, index) => {
                          return (
                            <th
                              key={index}
                              onClick={() => sortClicked(columnObj)}
                            >
                              {dashboardArgs.sortColumn ==
                                columnObj.dataField ? (
                                <span>
                                  {columnObj.text}
                                  <span
                                    className={
                                      dashboardArgs.sortOrder == "DESC"
                                        ? "sort-desc-icon"
                                        : dashboardArgs.sortOrder == "ASC"
                                          ? "sort-asc-icon"
                                          : "sort-default-icon"
                                    }
                                  ></span>
                                </span>
                              ) : (
                                <span>
                                  {columnObj.text}
                                  <span className="sort-default-icon"></span>
                                </span>
                              )}
                            </th>
                          );
                        })}
                        <th></th>
                      </tr>
                    </thead>
                  </table>
                  <div className="scroll-bar">
                    <table className="credit-list-table table-layout missing-receipts">
                      <tbody>
                        {dashboardList?.map((listValue, index) => {
                          return (
                            <tr
                              key={index}
                              onClick={() => openEditOverlay(listValue)}
                            >
                              <td>{listValue.cc_nbr}</td>
                              <td>{listValue.cc_trans_dt ? moment(listValue.cc_trans_dt).format('MM/DD/YYYY') : ''}</td>
                              <td className={parseFloat(listValue.cc_trans_amt, 10) > 0 ? 'positive' : 'negative'}>{parseFloat(listValue.cc_trans_amt, 10) > 0 ? '$' + listValue.cc_trans_amt : (listValue.cc_trans_amt == '' || listValue.cc_trans_amt == null) ? '' : '(' + '$' + listValue.cc_trans_amt.slice(1) + ')'}</td>
                              <td>{listValue.cc_trans_desc}</td>
                              <td>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    {dashboardList?.length <= 0 && (
                      <div className="no-records">No Data found</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      {dashboardList?.length > 0 && (
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={apipagination.countRows}
          pageSize={recordsPerPage}
          pageRange={apipagination.pageRange}
          setCurrentPage={setCurrentPage}
          setRecordsPerPage={setRecordsPerPage}
          onPageChange={(page) => setCurrentPage(page)}
          totalAmount={formattedValue1}
        />
      )}
    </>
  );
}