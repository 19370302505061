import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';

// create slice

const name = 'SaveReceipts';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const reimbursementSaveReceiptsActions = { ...slice.actions, ...extraActions };
export const reimbursementSaveReceiptsReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        saveReceipts: {}
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/Reimbursement`;

    return {
        postFromData: postFromData()
    };    

    function postFromData() {
       
            return createAsyncThunk(
                `${name}/postFromData`,
                async (postData)  => await fetchWrapper.post(`${baseUrl}/Save`, { postData})
            );
    }
}

function createExtraReducers() {
    return {
        ...postFromData()
    };

    function postFromData() {
        var { pending, fulfilled, rejected } = extraActions.postFromData;
        return {
            [pending]: (state) => {
                state.saveReceipts = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.success = action.payload;
                
            },
            [rejected]: (state, action) => {
                state.saveReceipts = { error: action.error };
            }
        };
    }
}
