
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {EditReceipt} from '../TekCCReceipts/EditReceipt/EditReceipt';
import {MileageReimbursementEditReceipt} from '../MileageReimbursement/MileageReimbursementEditReceipt/MileageReimbursementEditReceipt';
import {ReimbursementEditReceipt} from '../ExpenseReimbursement/ExpenseReimbursementEditReceipt/ExpenseReimbursementEditReceipt';
import { AddMissingReceipt } from '../MissingReceipts/AddMissingReceipt/AddMissingReceipt';
import { Header } from '../layouts/Header';
export function FormModal(props) {
  const { mileagereimbursement, reimbursement, missingreceipts, ...restProps } = props;
  return (
    <Modal
      {...restProps}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='form-modal'
    >
         <Header />   
         <Modal.Body>  
         {props.mileagereimbursement ? 
           <MileageReimbursementEditReceipt formProps = {props}></MileageReimbursementEditReceipt> :
           props.reimbursement ?  <ReimbursementEditReceipt formProps = {props}></ReimbursementEditReceipt> :
           props.missingreceipts ? <AddMissingReceipt formProps = {props}></AddMissingReceipt> :
        <EditReceipt formProps = {props}></EditReceipt> }       
      </Modal.Body>
   
    </Modal>
  );
}
