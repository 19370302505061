import { configureStore } from '@reduxjs/toolkit';

import { authReducer } from './auth.slice';
import { usersReducer } from './users.slice';
import { creditcardsReducer } from './creditcard.slice';
import { clientsReducer } from './clients.slice';
import { departmentReducer } from './department.slice';
import { payeeReducer } from './payee.slice';
import { projectsReducer } from './projects.slice';
import { tasksReducer } from './tasks.slice';
import { subtasksReducer } from './subtasks.slice';
import {  uploadReducer } from './upload.slice';
import {  saveReceiptsReducer } from './saveReceipts.slice';
import {  dashboardReducer } from './dashboard.slice';
import {  purchaseTypeReducer } from './purchaseType.slice';
import {  excelExportReducer } from './exportExcel.slice';
import {  deleteRecordReducer } from './deleteRecord.slice';
import {  reimbursementDashboardReducer } from './expenseReimbursementDashboard.slice';
import {  reimbursementSaveReceiptsReducer } from './reimbursementSaveReceipts.slice';
import { reimbursementExcelExportReducer  } from './expenseReimbursementExportExcel.slice';
import { reimbursementDeleteRecordReducer  } from './expenseReimbursementDeleteRecord.slice';
import { mileageReimbursementDashboardReducer  } from './mileageReimbursementDashboard.slice';
import { mileageReimbursementDeleteRecordReducer  } from './MileageReimbursementDeleteRecord.slice';
import { mileageReimbursementExcelExportReducer  } from './MileageReimbursementExportExcel.slice';
import { missingReceiptsExcelExportReducer  } from './missingReceiptsExportExcel.slice';
import { mileageRatesReducer  } from './mileageRates.slice';
import { missingReceiptsDashboardReducer  } from './missingReceiptsDashboard.slice';


export * from './auth.slice';
export * from './users.slice';
export * from './creditcard.slice';
export * from './clients.slice';
export * from './department.slice';
export * from './payee.slice';
export * from './projects.slice';
export * from './tasks.slice';
export * from './subtasks.slice';
export * from './upload.slice';
export * from './saveReceipts.slice';
export * from './dashboard.slice';
export * from './purchaseType.slice';
export * from './exportExcel.slice';
export * from './deleteRecord.slice';
export * from './expenseReimbursementDashboard.slice';
export * from './reimbursementSaveReceipts.slice';
export * from './expenseReimbursementExportExcel.slice';
export * from './expenseReimbursementDeleteRecord.slice';
export * from './mileageReimbursementDashboard.slice';
export * from './MileageReimbursementDeleteRecord.slice';
export * from './MileageReimbursementExportExcel.slice';
export * from './mileageRates.slice';
export * from './missingReceiptsDashboard.slice';
export * from './missingReceiptsExportExcel.slice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        users: usersReducer,
        creditcards: creditcardsReducer,
        clients: clientsReducer,
        departments: departmentReducer,
        payee: payeeReducer,
        projects: projectsReducer,
        tasks: tasksReducer,
        subtasks: subtasksReducer,
        upload: uploadReducer,
        saveReceipts: saveReceiptsReducer,
        dashboard: dashboardReducer,
        purchaseType: purchaseTypeReducer,
        excelExport: excelExportReducer,
        deleteRecord: deleteRecordReducer,
        reimbursementDashboard: reimbursementDashboardReducer,
        reimbursementSaveReceipts:reimbursementSaveReceiptsReducer,
        reimbursementExcelExport:reimbursementExcelExportReducer,
        reimbursementDeleteRecord:reimbursementDeleteRecordReducer,
        mileageReimbursementDashboard:mileageReimbursementDashboardReducer,
        mileageReimbursementDeleteRecord:mileageReimbursementDeleteRecordReducer,
        mileageReimbursementExcelExport:mileageReimbursementExcelExportReducer,
        mileageRates:mileageRatesReducer,
        missingReceipts:missingReceiptsDashboardReducer,
        missingReceiptsExcelExport:missingReceiptsExcelExportReducer
    },
});