import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './MileageReimbursementFilterDashboard.scss';
import {  userActions } from '../../_store';
import { clientsActions } from '../../_store';
import Select from 'react-select';
import CurrencyInput from '../../_components/CurrencyInput.tsx'
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import { mileageReimbursementDashboardActions } from "../../_store";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Navigate } from 'react-router-dom';
import moment from "moment";
import Spinner from 'react-bootstrap/Spinner';

const billTo = [
  { value: 'C', label: 'Client', key: 1 },
  { value: 'T', label: 'Tek', key: 2 },
]

export { MileageReimbursementFilterDashboard };

function MileageReimbursementFilterDashboard(props) {
  useEffect(() => {
    dispatch(userActions.getAll());
    dispatch(clientsActions.getAll());
  }, []);
  const dispatch = useDispatch();
  const { user: authUser } = useSelector(x => x.auth);
  const { users } = useSelector(x => x.users);
  const current = new Date();
  const Todaysdate = `${current.getFullYear()}-${(current.getMonth() > 9 ? (current.getMonth() + 1) : '0' + (current.getMonth() + 1))}-${current.getDate() > 9 ? current.getDate() : '0' + current.getDate()}`;
  const [currentDate, setDate] = useState(Todaysdate);
  const { clients } = useSelector(x => x.clients);
  const [noOfMiles, setNoOfMiles] = useState('');
  const [amount, setAmount] = useState('');
  const [odoStart, setOdoStart] = useState('');
  const [odoEnd, setOdoEnd] = useState('');
  const [billOption, selectBillTo] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [usedById, setUsedById] = useState('');
  const [clientClear, setClientClear] = useState(true);
  const userRef = useRef();
  const tDateFromRef = useRef();
  const tDateToRef = useRef();
  const billToRef = useRef();
  const clientRef = useRef();
  const fromRef = useRef();
  const toRef = useRef();
  const odometerstartRef = useRef();
  const odometerendRef = useRef();
  const noOfMilesRef = useRef();
  const amountRef = useRef();

  if (!authUser) {
    return <Navigate to="/login" state={{ from: history.location }} />
  }

  let userDefaultValue = {
    user_active: authUser.user.user_active,
    user_fname: authUser.user.user_fname,
    user_id: authUser.user.user_id,
    user_lname: authUser.user.user_lname,
    user_mname: authUser.user.user_mname
  }


  let bindFilterObjValues;
  bindFilterObjValues = JSON.parse(localStorage.getItem('mileageFilterObjValues'));

  if (users.length > 0) {
    userDefaultValue = users.find(obj => {
      return obj.user_id === bindFilterObjValues.expense_mileage_user_id;
    })

  }

  const usedByData = (e) => {
    if (e) {
      setUsedById(() => e.user_id);
      inputField.expense_mileage_user_id = e.user_id;
    } else {
      inputField.expense_mileage_user_id = '';

    }

  }

  const transStartDate = (data) => {
    setStartDate(data);
    if (data !== null) {
      inputField.expense_mileage_from_dt = moment(data).format('MM/DD/YYYY')
    } else {
      inputField.expense_mileage_from_dt = null;
    }
  }
  const transEndDate = (data) => {
    setEndDate(data);
    if (data !== null) {
      inputField.expense_mileage_to_dt = moment(data).format('MM/DD/YYYY')
    } else {
      inputField.expense_mileage_to_dt = null;
    }
  }

  const selectBill = (e) => {
    if (e) {
      selectBillTo(e.key);
      onChangeGetValue({ target: { value: e?.value, name: 'expense_mileage_bill_to' } })
    } else {
      selectBillTo(undefined)
      onChangeGetValue({ target: { value: e?.value, name: 'expense_mileage_bill_to' } })
    }
  }

  // const selectClient = (e) => {
  //   if (e) {
  //     setClientClear(true)
  //     onChangeGetValue({ target: { value: e?.client_id, name: 'expense_mileage_bill_to_client_id' } })
      
  //   } else {
  //     setClientClear(false)    
  //     onChangeGetValue({ target: { value: e?.client_id, name: 'expense_mileage_bill_to_client_id' } })
    
  //   }
  // };

  const onChangeNoOfMiles = (e) => {
    const value = e.target.value?.replace(/\D/g, "");
    setNoOfMiles(value);
    inputField.expense_mileage_no_of_miles = value;
  };

  const onChangeOdometerStart = (e) => {
    const value = e.target.value?.replace(/\D/g, "");
    setOdoStart(value);
    inputField.expense_mileage_odometer_start = value;
  };

  const onChangeOdometerEnd = (e) => {
    const value = e.target.value?.replace(/\D/g, "");
    setOdoEnd(value);
    inputField.expense_mileage_odometer_end = value;
  };

  const [inputField, setInputField] = useState({
    expense_mileage_user_id: usedById ? usedById : bindFilterObjValues.expense_mileage_user_id,
    expense_mileage_from_dt: startDate ? moment(startDate).format('MM/DD/YYYY') : bindFilterObjValues.expense_mileage_from_dt,
    expense_mileage_to_dt: endDate ? moment(endDate).format('MM/DD/YYYY') : bindFilterObjValues.expense_mileage_to_dt,
    expense_mileage_amount: bindFilterObjValues.expense_mileage_amount ? bindFilterObjValues.expense_mileage_amount : null,
    expense_mileage_from: bindFilterObjValues.expense_mileage_from ? bindFilterObjValues.expense_mileage_from : '',
    expense_mileage_to: bindFilterObjValues.expense_mileage_to ? bindFilterObjValues.expense_mileage_to : '',
    expense_mileage_odometer_end: bindFilterObjValues.expense_mileage_odometer_end ? bindFilterObjValues.expense_mileage_odometer_end : null,
    expense_mileage_odometer_start: bindFilterObjValues.expense_mileage_odometer_start ? bindFilterObjValues.expense_mileage_odometer_start : null,
    expense_mileage_no_of_miles: bindFilterObjValues.expense_mileage_no_of_miles ? bindFilterObjValues.expense_mileage_no_of_miles : null,
    // expense_mileage_bill_to: bindFilterObjValues.expense_mileage_bill_to ? bindFilterObjValues.expense_mileage_bill_to : '',
    // expense_mileage_bill_to_client_id: bindFilterObjValues.expense_mileage_bill_to == 'C' ? bindFilterObjValues.expense_mileage_bill_to_client_id : '',
    sortColumn: bindFilterObjValues.sortColumn,
    sortOrder: bindFilterObjValues.sortOrder,
    pageNumber: 1,
    pageSize: 25
  });

  const onChangeGetValue = e => {
    const { name, value } = e.target;
    setInputField(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };
  
  const amountChange = (e) => {
    let amountString = e.target.value;
    const amountStringValue = amountString.slice(1);
    setInputField((prevState) => ({
      ...prevState,
      expense_mileage_amount: amountStringValue,
    }));
  };

  let copyLocalObj = {};

  const handleSubmit = (event) => {
    event.preventDefault();
    props.sdata.onHide();
    localStorage.setItem('mileageFilterObjValues', JSON.stringify(inputField));
    props.sdata.setcurrentpage(1);
    props.sdata.setrecordsperpage(25)
    copyLocalObj = Object.assign({}, JSON.parse(localStorage.getItem('mileageFilterObjValues')));
    localStorage.setItem('mileagecopyLocalObj', JSON.stringify(copyLocalObj));
    dispatch(mileageReimbursementDashboardActions.getAll(inputField));
    inputField.pageNumber = '';
    inputField.pageSize = '';
  }

  const cancelModal = () => {
    props.sdata.onHide();
    if (localStorage.getItem('mileagecopyLocalObj')) {
      const resetForm = JSON.parse(localStorage.getItem('mileagecopyLocalObj'))
      localStorage.setItem('mileageFilterObjValues', JSON.stringify(resetForm));
    }
  }

  const resetFilter = () => {
    let Filterobj = {
      expense_mileage_from_dt: null,
      expense_mileage_to_dt: null,
      expense_mileage_from: "",
      expense_mileage_to: "",
      expense_mileage_odometer_start: null,
      expense_mileage_odometer_end: null,
      expense_mileage_no_of_miles: null,
      expense_mileage_amount:null,
      expense_mileage_user_id: authUser.user.user_id,
      // expense_mileage_bill_to: "",
      // expense_mileage_bill_to_client_id: null,
      sortColumn: 'expense_mileage_dt',
      sortOrder: 'ASC',
      exportExcel: "",
      pageNumber: 1,
      pageSize: 25
    }
    localStorage.setItem('mileageFilterObjValues', JSON.stringify(Filterobj));
    bindFilterObjValues = JSON.parse(localStorage.getItem('mileageFilterObjValues'));
    const resetUser = {
      user_active: authUser.user.user_active,
      user_fname: authUser.user.user_fname,
      user_id: authUser.user.user_id,
      user_lname: authUser.user.user_lname,
      user_mname: authUser.user.user_mname
    }

    userRef.current.setValue(resetUser);
    tDateFromRef.current.setSelected(null);
    setStartDate(null);
    inputField.expense_mileage_from_dt = '',
    setEndDate(null);
    inputField.expense_mileage_to_dt = '',
    billToRef.current?.setValue('');
    inputField.expense_mileage_bill_to = '';
    clientRef.current?.setValue('');
    // inputField.expense_mileage_bill_to_client_id = '';
    inputField.expense_mileage_from = '';
    inputField.expense_mileage_to = '';
    noOfMilesRef.current.value = '';
    inputField.expense_mileage_no_of_miles = null;
    odometerstartRef.current.value = '';
    inputField.expense_mileage_odometer_start = null;
    odometerendRef.current.value = '';
    inputField.expense_mileage_odometer_end = null;
    //onChangeGetValue({ target: { value: '', name: 'expense_mileage_trans_amt' } })
    inputField.expense_mileage_amount = '';
  }

  
  return (
    <div className="form-overlay">
      <div className="mileage-filter-dashboard">
        {( users.loading ) &&
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>}

        <form onSubmit={handleSubmit}>          
          <div className='filter-dashboard'>
            {users.length > 0 &&
              // (bindFilterObjValues.expense_mileage_bill_to_client_id ? clients.length > 0 : true) &&

              <div>
                <div className="field-group second-section">
                  <div className="field-element">
                    <div className="field">
                      <div className="label">Employee</div>
                      {users.length > 0 &&
                        <Select options={users}
                          isClearable
                          getOptionLabel={e => e.user_id}
                          getOptionValue={e => e.user_id}
                          defaultValue={userDefaultValue}
                          className="form-custom-class current-user"
                          classNamePrefix="form-custom-class-prefix" name="expense_mileage_user_id"
                          ref={userRef}
                          onChange={usedByData}
                        />
                      }


                    </div>
                  </div>
                  <div className="field-element">
                    <div className="field input-margin">
                      <label htmlFor="cc_trans_from_dt" className="label">Date Range</label>
                      <div className='d-range'>
                        <DatePicker
                          onChange={(date) => transStartDate(date)}
                          selectsStart
                          startDate={startDate}
                          maxDate={new Date()}
                          className='fm-date'                         
                          selected={startDate ? startDate : (bindFilterObjValues.expense_mileage_from_dt ? new Date(bindFilterObjValues.expense_mileage_from_dt) : null)}
                          ref={tDateFromRef}
                        />
                        <DatePicker
                          selected={endDate ? endDate : (bindFilterObjValues.expense_mileage_to_dt ? new Date(bindFilterObjValues.expense_mileage_to_dt) : null)}
                          onChange={(date) => transEndDate(date)}
                          selectsEnd
                          maxDate={new Date()}
                          endDate={endDate}
                          minDate={startDate}
                          className='to-date'
                          ref={tDateToRef}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="field-element">
                    <label htmlFor="expense_mileage_from" className="label">From{/*<span className="mandatory-field">* </span>*/}</label>
                    <div className="field">
                      <input name="expense_mileage_from" id="from" type="text" ref={fromRef} onChange={onChangeGetValue} defaultValue={bindFilterObjValues.expense_mileage_from} />
                    </div>
                  </div>
                  <div className="field-element">
                    <label htmlFor="expense_mileage_to" className="label">To{/*<span className="mandatory-field">* </span>*/}</label>
                    <div className="field">
                      <input name="expense_mileage_to" id="to" type="text" ref={toRef} onChange={onChangeGetValue} defaultValue={bindFilterObjValues.expense_mileage_to ? bindFilterObjValues.expense_mileage_to : ''} />
                    </div>
                  </div>
                </div>


                {/* {3RD ROW} */}
                {/* <div className="field-group third-section">

                  <div className="field-element">
                    <div className="field">
                      <label htmlFor="memo" className="label">Bill To</label>
                      <Select options={billTo}
                        onChange={(e) => selectBill(e)}
                        isClearable
                        ref={billToRef}
                        className="form-custom-class"
                        classNamePrefix="form-custom-class-prefix"
                        name="expense_mileage_bill_to"
                        defaultValue={billTo.find(obj => obj.value == bindFilterObjValues.expense_mileage_bill_to)}
                      />
                    </div></div>
                  {(billOption == 1 || bindFilterObjValues.expense_mileage_bill_to == 'C') && (billOption != 2 ) ? (
                    <div className="field-element" key="client">

                      <div className="field">
                        <label htmlFor="client" className="label">Client</label>
                        {clients.length > 0 &&
                          <Select options={clients}
                            isClearable
                            ref={clientRef}
                            getOptionLabel={e => e.client_name}
                            getOptionValue={e => e.client_id}
                            onChange={(e) => selectClient(e)}
                            className="form-custom-class"
                            classNamePrefix="form-custom-class-prefix"
                            name="expense_mileage_bill_to_client_id"
                            defaultValue={clients.find(obj => obj.client_id == bindFilterObjValues.expense_mileage_bill_to_client_id)}
                          />
                        }
                      </div>
                    </div>) :
                    (billOption == 2 || bindFilterObjValues.expense_mileage_bill_to == 'T') && (billOption != 1) ? (
                      <div className="field-element" key="purchase_type">
                        <div className="field">
                        </div>
                      </div>) :
                      (<div>
                      </div>)}
               
                </div>// */}
                <div className="field-group second-section" key="payee">
                  <div className="field-element">
                    <label htmlFor="expense_mileage_odometer_start" className="label">Odometer Start</label>
                    <div className="field">
                      <input name="expense_mileage_odometer_start" id="odo-start" defaultValue={bindFilterObjValues.expense_mileage_odometer_start ? bindFilterObjValues.expense_mileage_odometer_start : ''}
                       type="text" onChange={onChangeOdometerStart} ref={odometerstartRef} />

                    </div>
                  </div>
                  <div className="field-element">
                    <label htmlFor="expense_mileage_odometer_end" className="label">Odometer End</label>
                    <div className="field">
                      <input name="expense_mileage_odometer_end" id="odo-end" 
                      defaultValue={bindFilterObjValues.expense_mileage_odometer_end ? bindFilterObjValues.expense_mileage_odometer_end : ''} 
                      type="text" onChange={onChangeOdometerEnd} ref={odometerendRef} />
                    </div>
                  </div>
                  <div className="field-element">
                    <label htmlFor="expense_mileage_no_of_miles" className="label">Number Of Miles</label>
                    <div className="field">
                      <input name="expense_mileage_no_of_miles" id="noOfMiles" defaultValue={bindFilterObjValues.expense_mileage_no_of_miles ? bindFilterObjValues.expense_mileage_no_of_miles : ''}
                       onChange={onChangeNoOfMiles} type="text" ref={noOfMilesRef} />
                    </div>
                  </div>
                  <div className="field-element">
                    <div className="field">
                      <label htmlFor="expense_mileage_amount" className="label">Amount</label>
                      <CurrencyInput placeholder="$ 0.00" id="input-example" name="expense_mileage_amount"
                        type="text" onChange={amountChange}
                        defaultValue={bindFilterObjValues.expense_mileage_amount ? bindFilterObjValues.expense_mileage_amount : ''}
                        ref={amountRef}
                      />
                    </div>
                  </div>
                </div>
              </div>
            }

          </div>
          <div className='flex'>
            <OverlayTrigger
              key='bottom-filter'
              placement='bottom'
              overlay={
                <Tooltip id='tooltip-filter'>
                  Apply Filters
                </Tooltip>
              }
            >
              <Button type="submit"> <img src={'/assets/icons/checkmark.svg'} /></Button>

            </OverlayTrigger>
            <OverlayTrigger
              key='bottom-cancel'
              placement='bottom'
              overlay={
                <Tooltip id='tooltip-cancel'>
                  Cancel
                </Tooltip>
              }
            >
              <Button onClick={cancelModal} className="close-button"> <img src={'/assets/icons/close.svg'} /></Button>

            </OverlayTrigger>
            <OverlayTrigger
              key='bottom-reset'
              placement='bottom'
              overlay={
                <Tooltip id='tooltip-reset'>
                  Reset
                </Tooltip>
              }
            >
              <Button onClick={resetFilter} type="reset" defaultValue="Reset" className="reset-button"> <img src={'/assets/icons/reset.svg'} /></Button>
            </OverlayTrigger>
          </div>
        </form>
      </div>
    </div>
  );
}

